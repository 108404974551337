import { Dialog } from '@headlessui/react';
import {
  XIcon,
  PlusIcon,
  TruckIcon,
  UsersIcon,
  VideoCameraIcon,
  LinkIcon
} from '@heroicons/react/solid';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import withUser from '../redux/Hoc/withUser';
import Logo from '../assets/image/vinsyt-logo.svg';
import Modal from './Modal';
import { userLogout } from '../helpers/app';
import LogoutIcon from '../assets/image/icons/logout-icon.svg';
import LogoutIconColor from '../assets/image/icons/logout-icon-color.svg';

const Drawer = ({ user, isOpen, setIsOpen }) => {
  const { pathname } = useLocation();
  const [modal, setModal] = useState(false);

  return (
    <Dialog
      unmount={false}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed z-30 inset-0 overflow-y-auto">
      <div className="flex w-3/4 h-screen">
        <Dialog.Overlay
          className="z-40 fixed inset-0 bg-black bg-opacity-30"
          onClick={() => setIsOpen(!isOpen)}
        />
        <div
          className={`z-50 flex flex-col justify-between bg-white w-full
                       max-w-sm overflow-hidden text-left align-middle
                       shadow-xl`}>
          <div className="flex items-center justify-between p-6">
            <Dialog.Title className="">
              <img src={Logo} className="w-24" alt="logo" />
            </Dialog.Title>
            <Dialog.Description onClick={() => setIsOpen(!isOpen)}>
              <XIcon width={20} />
            </Dialog.Description>
          </div>
          <hr />
          <div className="flex flex-col grow px-6 mt-6">
            <Link
              to="/inventory"
              className={`flex gap-2 py-3 text-lg tracking-wide ${
                pathname === '/inventory' ? 'text-[#5046E4] font-bold' : ''
              }`}>
              <TruckIcon width={20} /> Inventory
            </Link>
            <Link
              to="/referrals"
              className={`flex gap-2 py-3 text-lg tracking-wide ${
                pathname === '/referrals' ? 'text-[#5046E4] font-bold' : ''
              }`}>
              <UsersIcon width={20} /> Create Referral
            </Link>
            <Link
              to="/owners"
              className={`flex gap-2 py-3 text-lg tracking-wide ${
                pathname === '/owners' ? 'text-[#5046E4] font-bold' : ''
              }`}>
              <VideoCameraIcon width={20} /> Video Testimonials
            </Link>
            <Link
              to="/add-vehicle"
              className={`flex gap-2 py-3 text-lg tracking-wide ${
                pathname === '/add-vehicle' ? 'text-[#5046E4] font-bold' : ''
              }`}>
              <PlusIcon width={20} /> Add Inventory
            </Link>
            <a
              href="https://revferral.atlassian.net/wiki/spaces/TRAIN/pages/2061533191/Understanding+vinsyt"
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 py-3 text-lg tracking-wide">
              <LinkIcon width={20} /> Training Portal
            </a>
          </div>
          <hr />
          <div className="px-6 py-3">
            <p className="font-bold text-md">
              {user?.name?.first} {user?.name?.last}
            </p>
            <p className="text-sm">{user?.email}</p>
          </div>
          <div className="">
            <button
              type="button"
              className="flex w-full gap-2 items-center justify-start text-white font-semibold bg-[#5046E4] px-6 py-4"
              onClick={() => setModal(true)}>
              <img className="w-4 h-4" src={LogoutIcon} alt="logout icon" />
              Logout
            </button>
          </div>
        </div>
      </div>
      <Modal
        modal={modal}
        customPadding
        icon={<img className="w-[27px] h-[27px]" src={LogoutIconColor} alt="logout icon" />}
        content="Are you sure you want to log out of this user?"
        positiveButton="Yes"
        negativeButton="No, cancel"
        onPositiveClick={() => userLogout()}
        onNegativeClick={() => setModal(false)}
      />
    </Dialog>
  );
};

export default withUser(Drawer);
