import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ArrowRightIcon, ClockIcon, SpeakerphoneIcon, TruckIcon } from '@heroicons/react/solid';
import { isEmpty } from 'lodash';
import SearchIcon from '../../assets/image/icons/search-icon.svg';
import Header from '../../components/Header';
import { checkPermission, filterByValue } from '../../helpers/app';
import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import NoInformation from '../../components/NoInformation';
import { callFBFunction } from '../../utils/firebase';
import withTestimonial from '../../redux/Hoc/withTestimonial';

const Sales = ({ user, setLoader, setTestimonial, resetTestimonial }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    checkPermission(user, navigate);
  }, []);

  useEffect(() => {
    if (pathname === '/owners') {
      resetTestimonial();
    }
  }, [pathname]);

  useEffect(() => {
    setLoader(true);
    callFBFunction('getOwners', { type: 'testimonial' }).then(({ data: response }) => {
      const { status, data } = response;
      if (status) {
        setLoader(false);
        setVehicles(data);
        setFilteredVehicles(data);
      } else {
        setLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    if (search.trim() === '') {
      setFilteredVehicles(vehicles);
    } else {
      setFilteredVehicles(filterByValue(vehicles, search));
    }
  }, [search, vehicles]);

  return (
    <>
      <Header title="Video Testimonials" subtitle="Select Customer" showBack={false} />
      <div className="flex flex-row gap-1 my-5 mx-5">
        <div className="flex-1 flex flex-row gap-2 items-center text-[12px] text-[#CDCDCD] border-b-[1px] border-[#EAEAED] pb-2.5">
          <img className="w-[17px] aspect-square" src={SearchIcon} alt="search" />
          <input
            className="flex-1 bg-transparent border-0 focus:ring-0 text-black placeholder:text-[#EAEAED] p-0"
            name="search"
            placeholder="Search"
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      {filteredVehicles && filteredVehicles.length > 0 ? (
        <div className="flex flex-col overflow-y-scroll mx-5 gap-2">
          {filteredVehicles.map((item, index) => (
            <div
              key={index}
              className="flex flex-row justify-between gap-2 p-2 rounded-lg border transition-all">
              <div className="relative flex-1 flex-col gap-2">
                <p className="text-sm font-semibold text-ellipsis overflow-hidden">
                  {item.name.first} {item.name.last}
                </p>
                <p className="flex gap-1 text-[11px] text-gray-500 text-ellipsis overflow-hidden">
                  <TruckIcon width={14} />
                  {
                    item?.vehicle?.attributes?.year
                  } {item?.vehicle?.attributes?.make?.toUpperCase()}{' '}
                  {item?.vehicle?.attributes?.model?.toUpperCase()}
                </p>
                <p className="flex gap-1 text-[11px] text-gray-500 text-ellipsis overflow-hidden">
                  <ClockIcon width={14} />
                  {!isEmpty(item.account.created_on_overwrite)
                    ? moment(item.account.created_on_overwrite).format('MMMM DD, YYYY')
                    : moment(item.account.created_on).format('MMMM DD, YYYY')}
                </p>
                <p className="flex gap-1 text-[11px] text-gray-500 text-ellipsis overflow-hidden">
                  <SpeakerphoneIcon width={14} /> {item?.referral || 0}
                </p>
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  onClick={() => {
                    setTestimonial({
                      owner_id: item.id
                    });
                    navigate('/select-video', { replace: true });
                  }}
                  className="bg-[#E4E7EB] text-[#333333] font-medium text-[12px] p-2 transition-all rounded-md">
                  <ArrowRightIcon width={15} />
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoInformation
          title="No Sales Available"
          content={
            <>
              We apologize, but it seems you're currently not having any sales. Our system updates
              regularly, so please check back soon or explore our{' '}
              <span className="text-[#5046E4]">add vehicle</span> feature to add new vehicle in the
              inventory.
            </>
          }
        />
      )}
    </>
  );
};

export default withLoader(withUser(withTestimonial(Sales)));
